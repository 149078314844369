import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, setFieldValue } from 'formik';
import styled, { css } from 'styled-components';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

const FieldSet = styled.div`
  display: block;
  width: calc(50% - 7.5px);
  margin-bottom: 15px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  &:last-of-type {
    width: 100%;
    margin-bottom: 40px;

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      margin-bottom: 18px;
    }
  }
`;

const Label = styled.label`
  font-size: 15px;
  font-weight: 300;
  color: ${props => props.theme.primary};
  position: absolute;
  left: 30px;
  top: 18px;
  transition: 0.3s;
  pointer-events: none;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    left: 20px;
  }
`;

const SubmitButton = styled.button`
  width: 246px;
  height: 60px;
  border-radius: 30px;
  border: 0;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(60deg, #f38726, #efba35);
  outline: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 172px;
    height: 44px;
    font-size: 12px;
  }

  &:disabled {
    background: linear-gradient(60deg, #a6998e, #939393);
  }
`;

const basicInputStyles = css`
  display: block;
  width: 100%;
  border: 0;
  background-color: #eeeeee;
  position: relative;
  height: 52px;

  &:focus,
  &:not(:placeholder-shown) {
    & ~ ${Label} {
      font-size: 12px;
      top: 12px;
    }
  }
`;
const StyledField = styled(Field)`
  ${basicInputStyles};
  padding: 15px 30px 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 15px 20px 0;
  }

  &::placeholder {
    color: transparent;
  }

  &.error {
    box-shadow: 0 0 0 1px red;
  }
`;

const StyledSelect = styled(Field)`
  ${basicInputStyles};
  width: calc(50% - 7.5px);
  padding: 0 30px;
`;

const StyledTextArea = styled(Field)`
  ${basicInputStyles};
  height: 180px;
  padding: 25px 30px;
  resize: none;
  line-height: 21px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 25px 20px;
  }

  &::placeholder {
    color: transparent;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Error = styled.div`
  color: #f52d2d;
  font-size: 13px;
  position: absolute;
  top: -18px;
  left: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 11px;
    top: -13px;
  }
`;

const StyledParagraph = styled.p`
  font-weight: 400;
  color: ${props => props.theme.primary};
  text-align: center;
  line-height: 33px;
  font-size: 17px;
  max-width: 560px;
  margin: 20px auto 0;
`;

const StyledLoader = styled.span`
  border: 3px solid currentcolor;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s loader linear infinite;
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 20px;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

class FooterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sentOk: false,
    };
  }

  // componentDidMount() {
  //   loadReCaptcha('6Lf3c-gUAAAAAO3dRbwCuhdkCjyOF6xCQj2xa-0i');
  // }

  verifyCallback = recaptchaToken => {
    return recaptchaToken;
  };

  validateEmail(value) {
    let error;
    if (!value) {
      error = 'Wymagane';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Niepoprawny adres e-mail';
    }

    return error;
  }

  validateName(value) {
    let error;
    if (!value) {
      error = 'Wymagane';
    }

    return error;
  }

  render() {
    const showMessage = () => {
      this.setState({
        isLoading: false,
        sentOk: true,
      });
    };

    const showLoader = () => {
      this.setState({
        isLoading: true,
        sentOk: false,
      });
    };
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: '',
          email: '',
          message: this.props.txtValue,
          recaptcha: '',
        }}
        onSubmit={(values, actions) => {
          showLoader();
          axios
            .post('https://mercho.pl/mail.php', values)
            .then(function(response) {
              actions.resetForm({
                name: '',
                email: '',
                message: '',
              });
              actions.setSubmitting(false);
              showMessage();
            })
            .catch(function(error) {
              console.log(error);
            });
        }}
      >
        {({ errors, touched, setFieldValue }) => {
          return (
            <>
              <StyledForm>
                {/*<ReCaptcha*/}
                {/*  sitekey="6Lf3c-gUAAAAAO3dRbwCuhdkCjyOF6xCQj2xa-0i"*/}
                {/*  action='homepage'*/}
                {/*  verifyCallback={this.verifyCallback}*/}
                {/*/>*/}
                <input
                  type="hidden"
                  id="g-recaptcha-response"
                  name="g-recaptcha-response"
                />
                <FieldSet>
                  <StyledField
                    id="name"
                    className={errors.name && 'error'}
                    type="text"
                    name="name"
                    placeholder="Imię i Nazwisko"
                    validate={this.validateName}
                  />
                  <Label htmlFor="name">Imię i Nazwisko</Label>
                  {errors.name && touched.name && <Error>{errors.name}</Error>}
                </FieldSet>
                <FieldSet>
                  <StyledField
                    id="email"
                    className={errors.email && 'error'}
                    type="email"
                    name="email"
                    validate={this.validateEmail}
                    placeholder="Email"
                  />
                  <Label htmlFor="email">Email</Label>
                  {errors.email && touched.email && (
                    <Error>{errors.email}</Error>
                  )}
                </FieldSet>
                <FieldSet>
                  <StyledTextArea
                    component="textarea"
                    id="message"
                    name="message"
                    placeholder="Wiadomość"
                    onChange={e => setFieldValue('message', e.target.value)}
                  />
                  <Label htmlFor="message">Wiadomość</Label>
                </FieldSet>
                <SubmitButton type="submit" disabled={this.state.isLoading}>
                  Wyślij wiadomość {this.state.isLoading && <StyledLoader />}
                </SubmitButton>
              </StyledForm>

              {this.state.sentOk && (
                <StyledParagraph>Wiadomość została wysłana</StyledParagraph>
              )}
            </>
          );
        }}
      </Formik>
    );
  }
}

export default FooterForm;
