import React, { Component } from 'react';
import FooterForm from './Form/Form';
import styled from 'styled-components';
import { Link } from 'gatsby';

import footerBackground from '../../assets/images/footer-border.png';
import phoneIcon from '../../assets/images/phone.svg';
import mailIcon from '../../assets/images/mail.svg';

const StyledHeader = styled.h2`
  color: ${props => props.theme.primary};
  font-size: 62px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 40px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 35px;
    display: block;
    text-align: center;
    line-height: 41px;
    margin-bottom: 20px;
  }
`;

const StyledParagraph = styled.p`
  font-weight: 400;
  font-family: 'Manrope';
  color: ${props => props.theme.primary};
  text-align: center;
  line-height: 33px;
  font-size: 17px;
  max-width: 560px;
  margin: 0 auto 30px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 15px;
    margin: 0 auto 30px;
  }
`;

const StyledParagraph2 = styled.p`
  font-weight: 300;
  color: ${props => props.theme.primary};
  text-align: center;
  line-height: 33px;
  font-family: 'Manrope';
  font-size: 17px;
  max-width: 560px;
  margin: 30px auto 30px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 15px;
    margin: 10px auto 40px;
  }
`;

const StyledFooter = styled.footer`
  padding: 141px 0 125px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 90px 0 60px;
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    height: 16px;
    width: 100%;
    background-image: url(${footerBackground});
    top: 0;
    left: 0;
    background-position-x: -20px;
    background-size: contain;

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      height: 10px;
    }
  }
`;

const Container = styled.div`
  display: block;
  padding: 0 20px;
  max-width: 840px;
  margin: 0 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`;

const Icon = styled.a`
  align-items: center;
  color: ${props => props.theme.primary};
  display: inline-flex;
  font-size: 18px;
  text-decoration: none;
  margin: 0 30px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0 30px 20px;
  }

  &::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    width: 36px;
    height: 36px;
  }
`;

const PhoneIcon = styled(Icon)`
  &::before {
    background-image: url(${phoneIcon});
  }
`;

const MailIcon = styled(Icon)`
  &::before {
    background-image: url(${mailIcon});
    margin-right: 10px;
  }
`;

const StyledLink = styled(Link)`
  color: #4f289a;
  position: absolute;
  text-decoration: none;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    bottom: 20px;
  }
`;

class Footer extends Component {
  static Heading = ({ children }) => <StyledHeader>{children}</StyledHeader>;

  static Paragraph = ({ children }) => (
    <StyledParagraph>{children}</StyledParagraph>
  );

  render() {
    return (
      <StyledFooter id="kontakt">
        <Container>
          {React.Children.map(this.props.children, child =>
            React.cloneElement(child)
          )}
          <IconsWrapper>
            <MailIcon href="mailto:biuro@mercho.pl">biuro@mercho.pl</MailIcon>
            <PhoneIcon href="tel:+48660279059">+48 660 279 059</PhoneIcon>
          </IconsWrapper>
          <StyledParagraph2>
            lub skorzystaj z naszego formularza
          </StyledParagraph2>
          <FooterForm txtValue={this.props.txtValue} />
        </Container>
        <StyledLink to="/polityka-prywatnosci">Polityka Prywatności</StyledLink>
      </StyledFooter>
    );
  }
}

export default Footer;
